import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { LANGUAGES } from '@shared/constants/languages';
import { replaceLanguageFromSlug } from '@shared/constants/languages';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import { BasePicker } from '../base-picker';

const LanguagePicker = ({ selected, options, openedWrapperProps, wrapperProps }) => {
  const { changeLanguage, languages: availableLanguages, language, originalPath } = useI18next();
  /**
   * lowercasing language values here to be used for changeLanguage function
   * as gatsby plugin uses that value to construct navigation path on language change
   * and also writing it to local storage for automatic redirects
   */
  const pickerOptions =
    options ||
    LANGUAGES.map((lang) => ({ ...lang, value: lang.value.toLowerCase() })).filter((language) =>
      availableLanguages.includes(language.value)
    );

  return (
    <BasePicker
      isMobileFullWidth
      selected={selected || { value: language.toLowerCase() }}
      options={pickerOptions}
      wrapperProps={wrapperProps}
      openedWrapperProps={openedWrapperProps}
      dataTestId="language-picker"
      onOptionClick={(option) => {
        changeLanguage(option.value, replaceLanguageFromSlug(originalPath));
        trackCustomGA4Event({
          eventName: GA4_EVENTS.languageChange,
          params: { language: option.value },
        });
      }}
    />
  );
};

export default LanguagePicker;
